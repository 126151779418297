<template>
    <div class="col-12 text-center">
        <div class="maximum-width">
            <template v-if="notEnoughData || validateTokenError">
                <!--Error-->
                <error></error>
                <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
                <p v-else-if="validateTokenError" class="alert alert-danger mt-5 text-center">{{validateTokenError}} {{$t('request-unsuccessful')}}</p>
            </template>
            <div v-else-if="showLoader">
                <div class="text-center mt-5">
                    <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div><br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {verifyToken} from "../../../services/backend.service";
import store from "../../../store/shared_state";

export default {
    name: 'Guard',
    data(){
        return{
            showLoader: true,
            notEnoughData: false,
            validateTokenError: null,
        }
    },
    components: {
        Error: () => import('../../others/Error'),
    },
    mounted() {
        const token = this.$router.history.current.query.token;

        console.log('-----token-----');
        console.log(token);

        if(token){
            this.verifyToken(token);
        } else {
            this.notEnoughData = true;
        }
    },
    methods: {
        verifyToken(token){
            verifyToken({token}).then(res => {

                console.log('-----verifyToken response-----');
                console.log(res);

                if(res.data.success && res.data.data.route){
                    store.setDataFromToken(res.data.data, 'file: guard.vue, method: verifyToken');
                    this.$router.push(res.data.data.route);
                } else{
                    this.validateTokenError = this.$t('error-2');
                }
            })
        },
    }
}
</script>

<style scoped></style>
